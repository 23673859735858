import { useState } from "react";
import MintedRecordData from "./mintedRecordModal";

export default function MintedRecordsTable(props) {
    const { data, setSelectedRow, setShowModal, dataFilter, selectedDB, page, limit } = props
    const [selectedData, setSelectedData] = useState()
    const [showData, setShowData] = useState(false)
    return (
        <div className="mt-5">
            {selectedData && <MintedRecordData data={selectedData || {}} setShowModal={setShowData} showModal={showData} headerText={"Minted Record detailed data"}  />}
            
            <h3>Minting records</h3>
            {
                (data &&
                    data?.length !== 0) ?
                    <>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover text-center align-middle">
                                <thead className="table-primary">
                                    <tr>
                                        {data &&
                                            data?.length !== 0 &&
                                            dataFilter[selectedDB?.name].map((key, index) => (
                                                <th scope="col" key={index}>
                                                    {key.name}
                                                </th>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((mintedOne, indx) => {
                                            return (
                                                <tr
                                                    className="table"
                                                    key={indx}
                                                    onClick={() => {
                                                        setSelectedData(mintedOne);
                                                        setShowData(true);    
                                                    }}>
                                                    <td>
                                                        {mintedOne['result']['Legal Name'] ?   mintedOne['result']['Legal Name'][0]['value'] :  mintedOne['result']['Company Name'] ?  mintedOne['result']['Company Name'][0]['value'] : mintedOne['result']['Entity Name'] ?mintedOne['result']['Entity Name'][0]['value'] : ""  }
                                                    </td>
                                                    <td>{mintedOne['result']['ABN'][0]['value'] || ''}</td>
                                                    <td>{mintedOne['result']['Postal code'][0]['value'] || ''}</td>
                                                    <td>{mintedOne['result']['State'][0]['value'] || ''}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div></>
                    :
                    <div>No data found
                    </div>
            }
        </div>
    )
}