import React, { useEffect, useState } from 'react';
import bgbanner from "../assets/bg-banner.jpg";
import { useLocation } from 'react-router-dom';
import { backend_url } from '../merc/config';
import axios from 'axios';
import Loader from "./loader";
import { request } from './common-tool';
import Toaster from './toaster';
import { useWeb3React } from '@web3-react/core';
import { personal_sign } from '../common/metamask';
import CompositeRecordModal from './compositeRecordActionModal';
import { width } from '../merc/style/button_style';
import AccordionData from './accordionData';

const Lodgeit = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState({
        data: {},
        index: null,
        key: null
    })
    const location = useLocation();
    const [showToast, setShowToast] = useState(false)
    const queryParams = new URLSearchParams(location.search);
    const abn = queryParams.get('abn');
    const source = queryParams.get('source');

    const [headers, setHeaders] = useState('')
    const [text, setText] = useState('')
    const context = useWeb3React()
    const [preview, setPreview] = useState(true)
    const { account } = context
    const [selectedIndex, setSelectedIndex] = useState(0)
    useEffect(() => {
        fetchCompositeRecords()
    }, [abn])
    const fetchCompositeRecords = () => {
        setIsLoading(true)
        axios.post(backend_url + 'composite-one-record', { abn: abn, source: source }).then((res) => {
            setShowToast(true)
            res?.status == 200 &&
                setResult(res?.data?.result)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error)
            setShowToast(true)
            setIsLoading(false)

        })
    }

    const handleMint = async () => {
    
        if (account) {
            try {
                var params = {}
                var reason = `CompositeRecordQuery=${abn}`;
                params.query = "Composite Record mint";
                params.address = `${context.account}`;
                let fee = 50;

                if (fee > 0) {
                    var seed = await new Promise(resolve => request(resolve, params.address, fee, reason));

                    if (seed === '') {
                        return;
                    }
                    else {
                        let vk = await personal_sign(seed, context.account);
                        params.verification = vk;
                        params.seed = seed;
                        if (vk) {
                            axios.post(
                                backend_url + "verify", params
                            )
                                .then(response => {
                                    const res = axios.post(backend_url + "mint-nft-composite", { result }).then((res) => {
                                        alert(res?.data?.message);
                                    }).catch((err) => {
                                        console.log(err)
                                        console.log(err?.response?.message || "error fetching response")
                                    })
                                }).catch((err) => {
                                    alert(err?.response?.data?.message || "Error in paying amount. Please try again later")
                                })
                        }
                    }
                }
            } catch (err) {
                console.log(JSON.stringify(err));
                alert('Failed to mint.');
            } finally {

            }
        }
        else {
            alert('Please login to use mint function')
        }

    };


    const downloadJson = async () => {

        try {
            const jsonLDData = JSON.stringify(result, null, 2);
            const blob = new Blob([jsonLDData], { type: 'application/ld+json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'response.jsonld';
            link.click();
            console.log('Download initiated!');
        } catch (error) {
            alert('Error download file. Please try again later')
            console.error('Error:', error);
        }
    }

    const actionFunction = async (element, index, key, type) => {

        if (type == "DELETE") {
            setPreview(false)
            result[key][index] && result[key].splice(index, 1)
            setTimeout(() => {
                setPreview(true)
                alert('Entry deleted successfully.')
            }, 10);
        }
        else if (type == "ADD") {
            setShowModal(true)
            setHeaders("Add new entry")
            let obj = {}
            Object.keys(result[key][index]).map((element, indexss) => {

                if (element !== "action") {
                    obj[element] = ""
                }
                else {
                    obj[element] = (result[key][index][element])
                }
            })
            console.log(obj)
            setSelectedRow({
                data: obj,
                index: null,
                key: key
            })

        }
        else {
            setHeaders("Edit entry Modal")
            setSelectedRow({
                data: result[key][index],
                index: index,
                key: key
            })
            setTimeout(() => {
                setShowModal(true)
            }, 100);

        }
        // console.log(result)
    }

    return (

        <>

            <Toaster />
            {showModal && <CompositeRecordModal setPreview={setPreview} result={result} setResult={setResult} setShowModal={setShowModal} data={selectedRow} headerText={headers} />}
            {isLoading && <Loader />}
            <section className="bsearch1-wrapper">
                <div className="position-relative banner-wrapper mb-3">
                    <img src={bgbanner} alt="banner" className="img-fluid" />
                    <div className="position-absolute banner-content">
                        <h3 className="fs-4">IS YOUR DATABASE REGISTERED?</h3>
                        <p>Check if your database is registered with us</p>
                    </div>
                </div>
                <div className='container'>
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <h5>Legal Entitiy Profile</h5>
                            <p>This pane displays the information of a legal entity derived from Australian Legal Entity Knowlege base. It has two versions: Free and Standard version. In the Free version, only the data from public resources (e.g. ABR) is available. In the Standard version, all the data built up by the Accziom community is available in the display or can be called via our API.</p>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <button
                                className="btn btn-primary btn-sm px-3  ms-2"
                                onClick={handleMint}
                            >
                                Mint Record (50 merc)
                            </button>
                            <button
                                className="btn btn-primary btn-sm px-3  ms-2"
                                onClick={downloadJson}
                            >
                                Download JSON-LD
                            </button>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="card">
                              <AccordionData preview={preview} result={result} setSelectedIndex={setSelectedIndex} actionFunction={actionFunction} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Lodgeit;