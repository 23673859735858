import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, Link, RouterProvider, useLocation } from "react-router-dom";
import ErrorPage from "./routes/errorpage";
import Home from "./home";
import "./index.css";
import TaxGeniiApi from "./routes/tax-genii-api/TaxGeniiApi";
import MERchantCoinView from "./merc";
import { Admin } from "./merc/admin/Admin";
import { AdminPreferences } from "./merc/admin/Preferences";
import { AdminServer } from "./merc/admin/Server";
import { AdminMonitor } from "./merc/admin/Monitor";
import { ChatbotView } from "./legislation/ChatbotView";
import { RelationRecognitionView } from "./service/RelationRecognition";
import { LegalSearch } from "./legislation/LegalSearch";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.min.js'

import {
  createTheme,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MainSearch } from "./search/MainSearch";
import { ChatbotHistoryView } from "./profile/ChatHistory";
import { AccountInfo } from "./account";
import { GlobalStateProvider, useGlobalState } from "./common/globalState";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { ProfileView } from "./profile/ProfileView";
import { NFTView } from "./profile/NFTView";
import { MERCView } from "./profile/MERCView";
import Help from "./routes/help";
import { isMobile } from "react-device-detect";
import { JSONLDDash } from "./merc/admin/jsonLDDashboar";
import { ReactComponent as Logo } from "./icons/accziom.svg";
import Profile from "./profile";
import { MainSearchJSON } from "./search/JSONLDSearch";
import 'bootstrap/dist/css/bootstrap.min.css';
import BSearch1 from "./search/bSearch";
import Lodgeit from "./search/Lodgeit";
const drawerWidth = 240;
const navItems = [
  // { title: "JSONLD Dashboard", path: "/jsonDashboard" },
  { title: "Dashboard", path: "/" },
  { title: "Data", path: "bsearchlisting" },
  // { title: "Tax Genii", path: "tax-genii" },
  // { title: "Legal Database", path: "legal-database" },
  // { title: "Relation Recognition", path: "relation-recognition" },
  { title: "Merchant Coin", path: "mercoin" },
  { title: "Help", path: "help" },
];

const mdTheme = createTheme();

function getLibrary(provider) {
  console.log(provider);
  const library = new Web3Provider(provider);
  library.pollingInterval = 5000;
  return library;
}

function ClientView(props) {
  const [state, dispatch] = useGlobalState();
  let show = true;
  if (
    state.pane === "Profile" ||
    state.pane === "NFT" ||
    state.pane === "Wallet"
  ) {
    show = false;
  }
  return (
    <Box
      component="main"
      sx={{
        bgcolor: "white",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p:
          !props.fullContent ||
            ["Profile", "NFT", "Wallet"].includes(state.pane)
            ? 8
            : 0,
      }}
    >
      <Box>
        <ProfileView show={state.pane === "Profile"} back={true} />
        <NFTView show={state.pane === "NFT"} back={true} />
        <MERCView show={state.pane === "Wallet"} back={true} />
        {props.child({ show: show })}
      </Box>
    </Box>
  );
}

function Frame(title, child, fullContent = false) {
  const currentPath = window.location.pathname;

  return (
    <div className="MainFrame">
      <GlobalStateProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <AppBar component="nav" sx={{ backgroundColor: "#FAFAFA", boxShadow: "none" }}>
                <Toolbar sx={{ pb: "10px" }}>
                  <Stack direction="row" alignItems="center" divider={<Divider sx={{ borderColor: "black" }} orientation="vertical" flexItem />} spacing={1} flexGrow={1}>
                    <Logo />
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ display: { xs: "none", sm: "block" }, color: "#146EB6", fontSize: "16px", fontWeight: 700 }}
                    >
                      {title}
                    </Typography>
                  </Stack>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    {navItems.map((item) => (
                      <Button
                        key={item.title}
                        sx={{ color: currentPath === `/${item.path}` ? "#146EB6" : "#000", fontSize: "12px", fontWeight: 700 }}
                        href={item.path}
                      >
                        {item.title}
                      </Button>
                    ))}
                  </Box>
                  {/* <Profile /> */}
                  <AccountInfo />
                </Toolbar>
              </AppBar>
              <ClientView child={child} fullContent={fullContent} />
            </Box>
          </ThemeProvider>
        </Web3ReactProvider>
      </GlobalStateProvider>
    </div>
  );
}

if (isMobile) {
  if (window.location.origin === "https://bsearchau.accziom.com") {
    window.location.href = window.location.href.replace(
      "bsearchau.accziom.com",
      "m.bsearchau.accziom.com"
    );
  }
  if (window.location.origin === "https://localhost") {
    window.location.href = window.location.href.replace(
      "localhost",
      "m.bsearchau.accziom.com"
    );
  }
} else {
  let router;
  router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "bsearch",
      element: Frame("bSearch", MainSearch),
    },
    {
      path: "tax-genii",
      element: Frame("Tax Genii", ChatbotView),
    },
    {
      path: "tax-genii-history",
      element: Frame("Tax Genii History", ChatbotHistoryView),
    },
    {
      path: "merc",
      element: Frame("Merchant Coin", ChatbotView),
    },
    {
      path: "mercoin",
      element: Frame("Merchant Coin", MERchantCoinView, true),
    },
    {
      path: "mercoin/admin",
      element: <Admin />,
    },
    {
      path: "mercoin/admin/preferences",
      element: <AdminPreferences />,
    },
    {
      path: "mercoin/admin/server",
      element: <AdminServer />,
    },
    {
      path: "mercoin/admin/monitor",
      element: <AdminMonitor />,
    },
    {
      path: "jsonDashboard",
      element: Frame("jsonDashboard", MainSearchJSON),
    },
    {
      path: "tax-genii-api",
      element: <TaxGeniiApi />,
    },
    {
      path: "relation-recognition",
      element: Frame("Relation Recognition", RelationRecognitionView),
    },
    {
      path: "legal-database",
      element: Frame("ATO Legal Database Search", LegalSearch),
    },
    {
      path: "help",
      element: Frame("ATO Legal Database Search", Help),
    },
    {
      path: "bsearchlisting",
      element: Frame("ATO Legal Database Search", BSearch1),
    }
    ,
    {
      path: "lodgeit",
      element: Frame("ATO Legal Database Search", Lodgeit),
    }
  ]);
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
