

export default function DataModal(props) {

  const { data, setShowModal, showModal, isComposite, headerText } = props
  const renderModalContent = () => {
    if (!data) return 'Loading...';

    const createContent = (obj) => {
      let content = '';
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            content += `<p><strong>${key}:</strong></p>`;
            content += `<div style="margin-left: 20px;">${createContent(obj[key])}</div>`; // Recursively handle nested object
          } else {
            content += `<p><strong>${key}:</strong> ${obj[key]}</p>`;
          }
        }
      }
      return content;
    };

    return createContent(data);
  };
  return (
    <div class="modal fade show mt-5" id="myModal" style={showModal ? { display: 'block' } : { display: 'none' }} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
    {headerText}
            <button type="button" class="btn-close" onClick={() => { setShowModal(false) }} aria-label="Close"></button>
          </div>
          {isComposite ?
            
              <div className="modal-body">
                <h6 className="mb-3">Do you want to generate page through AI or manually?</h6>
                <div className="d-flex justify-content-center">
                  <button 
                  onClick={()=>{
                    const url = `/lodgeit?abn=${data?.abn}&source=ai`;
                    window.open(url, '_self')
                  }} type="button" class="btn btn-primary me-3"><i class="bi bi-stars me-1"></i> Generate through AI</button>
                  <button onClick={()=>{
                      const url = `/lodgeit?abn=${data?.abn}&source=manual`;
                      window.open(url, '_self')
                  }} type="button" class="btn btn-outline-primary me-3"><i class="bi bi-pencil-square me-1"></i>Generate manually</button>
                </div>
              </div>
            :
             <div
             className="modal-body"
             dangerouslySetInnerHTML={{ __html: renderModalContent() }}
           />
          }
        
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" onClick={() => { setShowModal(false) }}>Close</button>
          </div>
        </div>
      </div>
    </div>
  )

}