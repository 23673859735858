export default function AccordionData (props) {
    const {preview, result, setSelectedIndex, actionFunction} = props
    return(
        <div className="card-body">
        {preview && <div className="accordion" id="accordionExample">
            {Object.keys(result).map((key, index) => {
                if (result[key] !== -1) {
                    return (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    onClick={() => { setSelectedIndex(index) }}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseOne${index}`}
                                    aria-expanded={true}
                                    aria-controls={`collapseOne${index}`}>
                                    {key}

                                </button>
                            </h2>
                            <div
                                id={`collapseOne${index}`}
                                className={`accordion-collapse collapse show`}
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="table-responsive">
                                        <table className="table  table-hover text-center align-middle">
                                            <thead className="table-primary">
                                                {result[key]?.map((elements, indexss) => {
                                                    if (indexss == 0) {
                                                        return (
                                                            <tr key={indexss}>
                                                                {Object.keys(elements).map((keyss, index) => {
                                                                    return (
                                                                        <th>
                                                                            {keyss}
                                                                        </th>
                                                                    )

                                                                })
                                                                }
                                                            </tr>
                                                        )
                                                    }

                                                })}


                                            </thead>
                                            <tbody>
                                                {
                                                    result[key]?.map((element, indexs) => {


                                                        return (
                                                            <tr key={indexs} className={`${element?.score == "High" ? "table-rows-success" : element?.score == "Medium" ? "table-rows-warning" : "table-rows-danger"
                                                                }`} >
                                                                {Object.keys(element).map((keyz, index) => {
                                                                    if (keyz == "action") {
                                                                        return (
                                                                            <td style={{width:"30%"}}>
                                                                                 <div className='d-flex justify-content-center align-items-center'>
                                                                                {element[keyz].map((actionss, indexxx) => {
                                                                                                     if (actionss == "DELETE") {
                                                                                                        return (<div className='circle-icons me-2' onClick={()=>{
                                                                                                            actionFunction(element, indexs, key, actionss)
                                                                                                        }}>
                                                                                                            <i class="bi bi-trash3 text-white"></i>
                                                                                                        </div>)

                                                                                                    }
                                                                                                    else if (actionss == "ADD") {
                                                                                                        return (
                                                                                                            <div className='circle-icons me-2' onClick={()=>{
                                                                                                                actionFunction(element, indexs, key, actionss)
                                                                                                            }}>
                                                                                                                <i class="bi bi-plus-lg text-white"></i>
                                                                                                            </div>
                                                                                                        )

                                                                                                    }
                                                                                                    else {
                                                                                                        return (
                                                                                                            <div className='circle-icons me-2' onClick={()=>{
                                                                                                                actionFunction(element, indexs, key, actionss)
                                                                                                            }}>
                                                                                                                <i class="bi bi-pencil text-white"></i>
                                                                                                            </div>
                                                                                                        )

                                                                                                    }
                                                                                 
                                                                                })}
                                                                               
                                                                                
                                                                                </div>
                                                                            </td>
                                                                        )


                                                                    }
                                                                    else {
                                                                        return (
                                                                            <td>{element[keyz]}</td>
                                                                        )
                                                                    }

                                                                })}
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            })}
        </div>}
    </div>
    )
}