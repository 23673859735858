import { element, object } from "prop-types"
import { useEffect, useState } from "react"


export default function CompositeRecordModal(props) {

  const {setPreview, result, setResult, data, setShowModal, showModal, headerText } = props

  const [dto, setDto] = useState(data?.data)

  useEffect(()=>{
    setDto(data?.data)
  },[data])
  const inputController = (e) =>{
      setDto((prev)=>{
        return{...prev , [e.target.id] : e.target.value}
      })
  }

  const confirmFunction = (e) =>{
      e.preventDefault();
      setPreview(false);
      (headerText == "Add new entry") ?  result[data?.key][result[data?.key]?.length] = dto : result[data?.key][data.index] = dto ;
      setTimeout(() => {
        setPreview(true)
        setShowModal(false)
        
      }, 100);
    
  }
  return (
    <div class="modal fade show mt-5" id="myModal" style={{ display: 'block' }} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <form onSubmit={confirmFunction}>
          <div class="modal-header">
            {headerText}
            <button type="button" class="btn-close" onClick={() => { setShowModal(false) }} aria-label="Close"></button>
          </div>


          <div className="modal-body">
            <h6 className="mb-3">Enter the details ?</h6>
            <div className=" justify-content-center">
              {
                Object.keys(data.data).map((element, index) => {
                  if(element !== 'action'){
                    return (
                      <div className="form-label mt-2" key={index}>
                        <label> {[element]}  </label>
                      <input  className="form-control" type="text" id={element} key={element} value={dto[element]} onChange={inputController} required />
                      
                      </div>
                    );
                  }
               
                })
              }
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" >Confirm</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  )
}