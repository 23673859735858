import { useState } from "react"
import AccordionData from "./accordionData"


export default function MintedRecordData(props) {

   
    const [headers, setHeaders] = useState('')

    const { data, setShowModal, showModal, headerText } = props
    const [preview, setPreview] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState()
    const actionFunction = async (element, index, key, type) => {
        alert('Not allowed for actions')
        console.log(element, index, key, type)
    }

    const downloadJson = async () => {

        try {
            const jsonLDData = JSON.stringify(data, null, 2);
            const blob = new Blob([jsonLDData], { type: 'application/ld+json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'response.jsonld';
            link.click();
            console.log('Download initiated!');
        } catch (error) {
            alert('Error download file. Please try again later')
            console.error('Error:', error);
        }
    }
    return (
        <div class="modal fade show mt-5" id="mintedRecordModal" style={showModal ? { display: 'block' } : { display: 'none' }} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        {headerText}
                        <button type="button" class="btn-close" onClick={() => { setShowModal(false) }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <button onClick={downloadJson} className="btn btn-primary">Download </button>
                        {data && <AccordionData preview={preview} result={data?.result || {}} setSelectedIndex={setSelectedIndex} actionFunction={actionFunction} />} 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={() => { setShowModal(false) }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )

}